import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './AppHeader.scss'
import './Common.scss'

function AppHeader(props) {
  const [showMenu, setShowMenu] = useState(false);
  const menuTransitionTime = 500

  const style_pad = {
    padding: "8px 0",
  }
  const svg_style1 = {
    enableBackground: "new 0 0 302 302",
  }
  const grad_stop1 = {
    stopColor: "rgb(30,65,200)",
    stopOpacity: "1",
  }
  const grad_stop2 = {
    stopColor: "rgb(15,199,238)",
    stopOpacity: "1",
  }

  const closeMenu = () => {
    setShowMenu(false);
  }

  /*
        <li className="u-nav-item">
            <a className="u-button-style u-nav-link active" href="/project_briefs">Project Briefs</a>
        </li>
          <li className="u-nav-item">
            <a className="u-button-style u-nav-link active" href="/project_briefs">Project Briefs</a>
          </li>
  */

  return (
    <header className="u-clearfix u-header ccg-header-palette u-header u-sticky" id="sec-902a">
    <div className="u-align-left u-clearfix u-sheet u-sheet-1">
    <Link to="/"><img alt="Chameleon Consulting Group" className="header-logo" src="/images/chameleon_header.png"/></Link>
    <nav className="u-menu u-menu-dropdown u-offcanvas ccg-menu u-enable-responsive" data-responsive-from="XL">
      <div className="menu-collapse">
        <div className="u-button-style u-nav-link u-text-body-alt-color clickable" style={style_pad} onClick={() => setShowMenu(true)}>
          <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 302 302">
            <use xlinkHref="#svg-a760"></use>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="svg-a760" x="0px" y="0px" viewBox="0 0 302 302" style={svg_style1} xmlSpace="preserve" className="u-svg-content">
            <defs>
              <linearGradient id="menu_grad1" x1="-25%" y1="0%" x2="75%" y2="0%">
                <stop offset="0%" style={grad_stop1} />
                <stop offset="100%" style={grad_stop2} />
              </linearGradient>
              <linearGradient id="menu_grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={grad_stop1} />
                <stop offset="100%" style={grad_stop2} />
              </linearGradient>
              <linearGradient id="menu_grad3" x1="25%" y1="0%" x2="125%" y2="0%">
                <stop offset="0%" style={grad_stop1} />
                <stop offset="100%" style={grad_stop2} />
              </linearGradient>
            </defs>
            <g color="#1e34d0">
              <rect y="36" width="302" height="30" fill="url(#menu_grad1)"></rect>
              <rect y="136" width="302" height="30" fill="url(#menu_grad2)"></rect>
              <rect y="236" width="302" height="30" fill="url(#menu_grad3)"></rect>
            </g>
          </svg>
        </div>
      </div>
      <div className="u-nav-container">
        <ul className="u-nav u-spacing-25 u-unstyled">
          
        <li className="u-nav-item">
          <Link className="u-button-style u-nav-link active" to="/">Home</Link>
        </li>
        <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/capabilities">Capabilities</Link>
        </li>
        <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/careers">Careers</Link>
        </li>
        <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/internships">Internships</Link>
        </li>
        <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/about">About</Link>
        </li>
        </ul>
      </div>
      <CSSTransition in={showMenu} timeout={menuTransitionTime} classNames="nav-container-show">
      <div className={"u-nav-container-collapse"}>
        <CSSTransition in={showMenu} timeout={menuTransitionTime} classNames="overlay-fade">
          <div className="u-black u-menu-overlay u-opacity u-opacity-70" onClick={closeMenu}></div>
        </CSSTransition>
        <div className="u-black u-container-style u-inner-container-layout u-opacity u-sidenav nav-default">
          <div className="u-menu-close clickable" onClick={closeMenu}></div>
          <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
          
          <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/" onClick={closeMenu}>Home</Link>
          </li>
          <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/capabilities" onClick={closeMenu}>Capabilities</Link>
          </li>
          <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/careers" onClick={closeMenu}>Careers</Link>
          </li>
          <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/internships" onClick={closeMenu}>Internships</Link>
          </li>
          <li className="u-nav-item">
            <Link className="u-button-style u-nav-link active" to="/about" onClick={closeMenu}>About</Link>
          </li>
          </ul>
        </div>
      </div>
      </CSSTransition>
    </nav>
    </div>
    </header>
  );
}

export default AppHeader;
