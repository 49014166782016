import './Common.scss';
import './Downloads.scss';
import React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { theme } from './ThemeOverrides';
import { ScrollToTop  } from './Common';

function Downloads(props) {
  const [fileList, setFileList] = useState(null);

  function getTitle() {
    if (!fileList)
        return "Not Found";

    return "Project Briefs";
  }

  useEffect(() => {
    fetch("/project_briefs/file_list")
      .then((response) => {
        return response.redirected ? [] : response.json();
      })
      .then((data) => {
        setFileList(data);
      });
  }, []);

  function getFileList() {
    if (fileList === null) {
      return (
        <TableRow>
          <TableCell>
            <div className="file-download-item">No downloads available</div>
          </TableCell>
        </TableRow>
      );
    }

    if ( fileList.length === 0 ) {
      return (
        <Redirect
          to={{
            pathname: "/project_briefs",
            search: "?err_msg=Session+expired+or+not+established.+Password+Required.",
          }}
        />
      );
    }

    return fileList.map((file) =>
      <TableRow>
        <TableCell>
          <a className="link-text" href={["/downloads/project_briefs", file].join('/')} target="_blank" rel="noreferrer">
            <div className="file-download-item">{file}</div>
          </a>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
    <div className="scale-left-container">
      <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
    </div>
    <div className="scale-right-container">
      <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
    </div>
    <section className="u-clearfix u-section-5">
      <ScrollToTop />
      <div className="u-clearfix u-sheet u-sheet-1 download-sheet">
        <h2 className="align-center">{getTitle()}</h2>
          <ThemeProvider theme={theme}>
            <TableContainer>
              <Table aria-label="jobs table">
                {getFileList()}
              </Table>
            </TableContainer>
          </ThemeProvider>
      </div>

    </section>
    </div>
  );
}

export default Downloads
