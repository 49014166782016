import './App.scss';
import './Internships.scss';
import { React } from 'react';
import { HashLink } from 'react-router-hash-link';

import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from './ThemeOverrides';
import { ScrollToTop, scrollWithHeaderOffset } from './Common';

import IconTitleBlockList from './IconTitleBlock';

function Internships(props) {
  const goals = [
    {
      image: "/images/internships/learn.png",
      image_alt: "learn",
      title: "Learn",
      content: "Interns and Co-Ops work directly with full time engineers and are completely integrated into existing project teams, supporting real customer goals. You will have the opportunity to witness and participate in the agile development life cycle, expand your understanding of or learn a new programming language, and gain experience with modern design and architecture approaches like microservices, containerization, and CI/CD.",
    },
    {
      image: "/images/internships/contribute.png",
      image_alt: "contribute",
      title: "Contribute",
      content: "To date, interns have helped build tools that are on the front lines of cyber warfare, identified and helped mitigate critical bugs in software applications ahead of deployment, and written user guides that have received recognition from high ranking military service members. Our ultimate endstate is that interns and Co-Ops leave their fingerprints on something operational.",
    },
    {
      image: "/images/internships/have_fun.png",
      image_alt: "have fun",
      title: "Have Fun",
      content: "Your time in our program may be limited but the memories will last. We ensure you will have the opportunity to engage with leaders at all levels of CCG with happy hours, technical brown bags, and program overviews. We also provide opportunities outside of work to get to know your fellow interns and co-workers on a deeper level with company outings to include Washington Nationals baseball games, our summer company picnic, and if you Co-Op in the Fall, our yearly holiday party!",
    },
    {
      image: "/images/internships/get_hired.png",
      image_alt: "get hired",
      title: "Get Hired!",
      content: "Whether you want to pursue a career supporting the Cyber Operations mission or just want to gain hands-on experience with modern technology, CCG will help you stand out by blending in!",
    },
  ];

/*
            <a className="link-text" href="https://recruitingbypaycor.com/career/CareerHome.action?clientId=8a7883d07c5da337017cdd3fe17a7c24&specialization=&source=&lang=en"
            > Internship & Co-op opportunities</a>
*/
  return (
    <ThemeProvider theme={theme}>
      <div className="scale-left-container">
        <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
      </div>
      <div className="scale-right-container">
        <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
      </div>
      <section className="u-clearfix u-section-5">
        <ScrollToTop />
        <div className="u-clearfix u-sheet u-sheet-1 internships-sheet">
          <div className="u-container-layout u-container-layout-1">
            <h2 className="align-center">Internships at CCG</h2>
          </div>


<p>
The goal of the CCG internship and Co-Op experience is to expose students to the unique and fulfilling challenges that engineers face while supporting the Cyber Operations mission for the Department of Defense. We achieve this goal by following the mantra of "learn, contribute, have fun, and get hired."
</p>

          <div className="gradient-horizontal-rule" />

          <h2 className="align-center">Program Goals</h2>

          <IconTitleBlockList blocks={goals} />

          <div className="gradient-horizontal-rule" />

          <h2 id="job_listing" className="align-center">Internship & Co-Op Opportunities</h2>

          <p className="u-align-center">
            Apply to our 
            <HashLink to="/careers#job_listing" className="link-text"
                  scroll={el => scrollWithHeaderOffset(el)}
            > Internship & Co-op opportunities</HashLink>
            , or feel free to reach out to us at <a href="mailto:contact@chameleoncg.com" target="_blank" rel="noreferrer" className="link-text">contact@chameleoncg.com</a>
          </p>


      </div>
      </section>

    </ThemeProvider>
  );
}

export default Internships
