import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return null;
}

const scrollWithHeaderOffset = el => {
  el.scrollIntoView(true);
  window.scrollBy(0,-80);
}

export { ScrollToTop, scrollWithHeaderOffset }
