import './Common.scss';
import './Capabilities.scss';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLock } from '@fortawesome/free-solid-svg-icons'

import { ScrollToTop, scrollWithHeaderOffset } from './Common';

import IconTitleBlockList from './IconTitleBlock';

function Capabilities(props) {
  const engineering_items = [
    {
      image: "/images/capabilities/microservices_dev.png",
      image_alt: "microservices application design and development",
      title: "Microservices Application Design & Development",
      content: "Modern software requires that services are easily composable, scale dynamically, deploy with zero downtime, and are able to be tested as a unit. Microservices, along with supporting infrastructure is an effective approach to solving those needs in an elegant way that builds confidence in our solutions and software.",
    },
    {
      image: "/images/capabilities/vulnerability_research.png",
      image_alt: "security and vulnerability research",
      title: "Security & Vulnerability Research",
      content: "Armed with extensive mission context and knowledge of how hardware and software systems operate, engineers utilize reverse engineering and analysis to identify possible exploitable defects. Leverage a combination of open-source and proprietary tools and techniques (input vector analysis, fuzzing, static/dynamic analysis, etc.) to deliver research products with practical applications.",
    },
    {
      image: "/images/capabilities/cicd_dev.png",
      image_alt: "CI/CD Pipeline Design and Development",
      title: "CI/CD Pipeline Design & Development",
      content: "Continuous Integration and Deployment is a must for building software at the pace CCG requires to meet our customer’s needs. It is paramount to catch issues as early in the development process as possible and allow automation to test and create reproducible releases. It is increasingly used to accelerate accreditation processes in order to enable fielding at a more rapid rate.",
    },
    {
      image: "/images/capabilities/infrastructure_dev.png",
      image_alt: "enterprise level platform and infrastructure design and development",
      title: "Enterprise-Level Platform & Infrastructure Design & Development",
      content: "Writing software is just a piece of the puzzle. Often the platform in which the software runs is an overlooked aspect. Under this umbrella lives technologies such as Kubernetes, system-wide monitoring and telemetry tools, authentication, and service meshes. CCG builds reusable, cloud-agnostic platforms that run on a variety of systems and support everything from development to production stacks.",
    },
    {
      image: "/images/capabilities/cno_tool_dev.png",
      image_alt: "CNO tool development",
      title: "CNO Tool Development",
      content: "Apply low level knowledge of operating systems, computer networking concepts, and cyber tradecraft to design, build, and deliver effective tools focused on the end-user experience and successful mission outcome.",
    },
  ];

  const special_proj_items = [
    {
      image: "/images/capabilities/risk_management.png",
      image_alt: "enhanced risk management",
      title: "Risk Management",
      content: "We assist our customers in the formulation and implementation of effective strategies and mechanisms to reduce their risk of compromise to sensitive operations. Our personnel have the operational experience to assist in the detailed analysis required to develop, maintain and execute a full suite or risk management tools.",
    },
    {
      image: "/images/capabilities/digital_tradecraft_ops.png",
      image_alt: "digital tradecraft",
      title: "Digital Tradecraft",
      content: "CCG is at the forefront of advanced operational and developmental concepts in the execution of synthetic operations and executing digital tradecraft.  Collectively our personnel have over 150 years of experience in the execution of physical and digital tradecraft and conducting physical and virtual operations.",
    },
    {
      image: "/images/capabilities/capability_dev.png",
      image_alt: "strategy and capability development",
      title: "Strategy & Capability Development",
      content: "CCG personnel have been at the forefront of synthetic operations and executing tradecraft in support of online operations.  Our Special Project Subject Matter Experts can assist with the full spectrum of strategy and capabilities development - from building a customer's capability from scratch, to maturing current operations by assisting with operational strategy development.",
    },
    {
      image: "/images/capabilities/tech_integration.png",
      image_alt: "technology integration",
      title: "Technology Integration",
      content: "A clear differentiator between CCG and our competition is our ability to design, construct and deliver capabilities that will impact their mission immediately through the integration of technology into our operations support portfolio.  We draw heavily from our engineering expertise to provide our customers with comprehensive solutions to their most challenging problems.",
    },
  ];

  /*
        <HashLink to="/project_briefs" className="link-line"
                  scroll={el => scrollWithHeaderOffset(el)}
        >
          Project Briefs <FontAwesomeIcon icon={faLock} /> &#x2794;
        </HashLink>
  */

  return (
   <div>
    <div className="scale-left-container">
      <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
    </div>
    <div className="scale-right-container">
      <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
    </div>
    <section className="u-clearfix u-section-5">
      <ScrollToTop /> 
      <div className="u-clearfix u-sheet u-sheet-1 capability-sheet">
        <h2 className="align-center">Winning the Fight by<br/>Leading the Conversation</h2>
        <p>
        Chameleon Consulting Group stands at the intersection of Cyberspace Operations and Information Operations. We combine expertise with cutting edge, modern technology and digital tradecraft to fully solve the most challenging problems facing our DoD and IC partners.
        </p>
      </div>
    </section>

    <section className="u-clearfix u-gradient u-section-5" id="sec-8d6d">
      <div className="u-clearfix u-sheet u-sheet-1 capability-sheet">

        <div className="gradient-horizontal-rule" />

        <h3 id="engineering" className="align-center">Engineering</h3>
        <p>
        Our engineering team is comprised of software engineers, research scientists, quality assurance engineers, CNO tool developers, and DevOps engineers with an average of 10 years of experience. Our consultative, customer centric approach is similar to how we address bugs in code; rather than simply resolving an isolated problem, we look around the problem and work to improve the entire system wherever possible.
        </p>

        <HashLink to="/careers#job_listing" className="link-line"
                  scroll={el => scrollWithHeaderOffset(el)}
        >
          Apply to join the engineering team &#x2794;
        </HashLink>

        <IconTitleBlockList blocks={engineering_items} />


        <div className="gradient-horizontal-rule" />

        <h3 id="special_projects" className="align-center">Special Projects</h3>
        <p>
Our special projects team leverages expertise across the full-spectrum of operations support. From enhanced risk management solutions, to synthetic operations and technology integration, we are experts at enabling the right tool to be in the right place at the right time, regardless of the environment. With an average of 15 years of experience in both conducting and enabling operations in support of our nation{"'"}s conventional and unconventional units, our team has an unmatched understanding of the challenges facing our customers and the expertise necessary to effectively solve those challenges.
        </p>

        <HashLink to="/careers#job_listing" className="link-line"
                  scroll={el => scrollWithHeaderOffset(el)}
        >
          Apply to join the special projects team &#x2794;
        </HashLink>

        <br/>

        <IconTitleBlockList blocks={special_proj_items} />

      </div>
    </section>

   </div>
  );
}

export default Capabilities
