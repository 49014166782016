import './IconTitleBlock.scss';
import React from 'react';

function IconTitleBlock({ image, image_alt, title, content}) {
  return (
    <div className="icon-title-block">
      <div className="icon-title-block-header">
        <div className="icon-title-block-image-container">
          <img className="icon-title-block-image" alt={image_alt} src={image} />
        </div>
        <h4 className="icon-title-block-title" dangerouslySetInnerHTML={{__html: title}}></h4>
      </div>
      <div>
        {content}
      </div>
    </div>
  );
}

function IconTitleBlockList({blocks}) {
  var iconTitleBlocks = [];
  for (const block of blocks) {
    iconTitleBlocks.push(<IconTitleBlock {...block} key={block["title"]}/>);
  }

  return (
    <div className="icon-title-block-container">
      {iconTitleBlocks}
    </div>
  )
}

export default IconTitleBlockList
