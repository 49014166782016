import './Common.scss';
import './Downloads.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ScrollToTop  } from './Common';

function Whitepapers(props) {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const err_msg = queryParams.get("err_msg");

  function displayError() {
    if (err_msg === null) {
        return (null);
    }

    return (<p className="align-center error-text">{err_msg}</p>)
  }

  return (
    <div>
    <div className="scale-left-container">
      <img className="scale-left-img" alt="scale_left" src="/images/scale_left_white.png" />
    </div>
    <div className="scale-right-container">
      <img className="scale-right-img" alt="scale_right" src="/images/scale_right_white.png" />
    </div>
    <section className="u-clearfix u-section-5">
      <ScrollToTop />
      <div className="u-clearfix u-sheet u-sheet-1 download-sheet">
        <h2 className="align-center">Project Briefs</h2>
        <p>
Thank you for your interest in learning more about CCG’s capabilities and programs. To access the information, please log in with your provided username and password. 
        </p>

        <p className="align-center">
        <form method="POST" action="/project_briefs/auth">
          <div className="pw-field"><input type="password" name="password" placeholder="Enter password" tabindex="1" /></div>
          <div><button tabindex="2">Submit</button></div>
        </form>
        </p>

        {displayError()}

        <div className="gradient-horizontal-rule" />

	<h4 className="align-center">
<i>Confidentiality Disclaimer</i>
	</h4>

        <p>
<i>
The following documents are strictly confidential and contain privileged material that are solely for the use of the recipient and may not be reproduced or circulated without prior written permission from Chameleon Consulting Group, LLC. Any review, retransmission, dissemination or other use of, or taking of any action in reliance upon this information by persons or entities other than the intended recipient is prohibited. If you are not the intended recipient, you may not disclose or use the information contained within these documents in any manner.
</i>
        </p>

        <br/>
        <br/>
        <br/>
        <br/>
        <p className="align-center">
For questions or for technical help, email <a href="mailto:contact@chameleoncg.com" target="_blank" rel="noreferrer" className="link-text">contact@chameleoncg.com</a> for assistance.
        </p>


      </div>

    </section>
    </div>
  );
}

export default Whitepapers
