import './JobPost.scss';
import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

  
function JobPost(props) {
  const {
    title,
    locationMetro,
    locationCity,
    locationState,
    displayUrl,
  } = props;

  const get_location = (metro, city, state) => {
    const cityState = (city ? city + ", " + state : state);

    if (metro) {
      return <span className="job-location">{metro}<br/>{cityState}</span>
    }

    return <span className="job-location">{cityState}</span>
  }

  /*
            <span className="job-id">Req #{id}</span> <br/>
  */

  return (
    <TableRow>
        <TableCell>
          <a className="job-cell-link" href={displayUrl} target="_blank" rel="noreferrer">
          <div>
            <span className="job-title">{title}</span> <br/>
            {get_location(locationMetro, locationCity, locationState)}
          </div>
          </a>
        </TableCell>
    </TableRow>
  );
}

export default JobPost;
