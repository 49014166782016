import './Common.scss';
import './Home.scss';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { ScrollToTop, scrollWithHeaderOffset } from './Common';
  
function Home(props) {

  return (
    <div>
    <div className="home-scale-left-container">
      <img className="home-scale-left-img" alt="scale_left" src="/images/scale_left.png" />
    </div>
    <div className="home-scale-right-container">
      <img className="home-scale-right-img" alt="scale_right" src="/images/scale_right.png" />
    </div>
    <section className="u-clearfix u-image u-section-2" id="sec-e3b4">
        <ScrollToTop />
        <div className="u-clearfix u-sheet">
              <div className="clarity-container">
                <div className="clarity-logo-container">
                  <img alt="Clarity name and logo" src="/images/blue_horizontal_logo_rgb.svg" />
                </div>
                <div className="clarity-combines-container">Chameleon Consulting Group and Clarity Combine Capabilities:</div>
              </div>
              <div className="clarity-more-info-container">
                <div className="clarity-provides-container">Now providing next generation systems and cyberspace products and services to the Defense Department and Intelligence Community.</div>
                <div className="clarity-learn-more-container">
                  <a className="u-btn u-button-style u-blue u-btn-1 clarity-learn-more-btn" href="https://www.linkedin.com/pulse/copy-chameleon-consulting-group-clarity-combine-capabilities-5c6te" target="_blank" rel="noreferrer">LEARN MORE</a>
                </div>
              </div>
        </div>
    </section>

    <div className="clarity-mountain-container">
      <img alt="mountain banner" src="/images/ccg_mountain_banner.svg" />
    </div>

    <section className="u-clearfix u-image u-section-4 home-section-4" id="sec-8d6d">
        <div className="u-clearfix u-sheet u-sheet-1">

            <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-spacing-vertical u-layout-wrap u-layout-wrap-1">
                <div className="u-gutter-0 u-layout">
                    <div className="u-align-center u-container-layout-1">
                      <div className="section-title-container">
                        <h2 className="u-text u-text-1">
                          Vanguards of the Cyber and Information Operations Domain
                        </h2>
                      </div>
                    </div>
                    <div className="u-layout-row">
                        <div className="u-align-center u-container-style u-layout-cell u-size-30 u-size-30-md u-layout-cell-2">
                            <div className="u-container-layout u-valign-top u-container-layout-2">
                              <span className="capability-image-icon">
                                <HashLink to="/capabilities#engineering"
                                          scroll={el => scrollWithHeaderOffset(el)}
                                >
                                  <img className="capability-image" alt="Engineering" src="/images/engineering.png" />
                                </HashLink>
                              </span>
                              <div>
                                <HashLink to="/capabilities#engineering"
                                          scroll={el => scrollWithHeaderOffset(el)}
                                >
                                  <h4 className="u-text u-text-3">
                                    ENGINEERING
                                  </h4>
                                </HashLink>
                              </div>
                              <p className="u-text u-text-4">
                                Security &amp; Vulnerability Research<br/>
                                CNO Tool Development<br/>
                                Microservices Applications<br/>
                                CI/CD Pipelines<br/>
                                Enterprise-Level Platform &amp; Infrastructures<br/>
                              </p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-layout-cell u-size-30 u-size-30-md u-layout-cell-3">
                            <div className="u-container-layout u-valign-top u-container-layout-2">
                              <span className="capability-image-icon">
                                <HashLink to="/capabilities#special_projects"
                                          scroll={el => scrollWithHeaderOffset(el)}
                                >
                                  <img className="capability-image" alt="Special Projects" src="/images/special_projects.png" />
                                </HashLink>
                              </span>
                              <div>
                                <HashLink to="/capabilities#special_projects"
                                          scroll={el => scrollWithHeaderOffset(el)}
                                >
                                  <h4 className="u-text u-text-3">
                                    SPECIAL PROJECTS
                                  </h4>
                                </HashLink>
                              </div>
                              <p className="u-text u-text-4">
                                Risk Management<br/>
                                Digital Tradecraft<br/>
                                Strategy &amp; Capability Development<br/>
                                Technology Integration<br/>
                              </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="u-clearfix u-section-5" id="sec-469d"><div className="u-clearfix u-sheet u-sheet-1">
          <div className="gradient-horizontal-rule" />

            <div className="u-container-style u-expanded-width-lg u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
                <div className="u-container-layout u-container-layout-1">
                    <h2 className="u-align-center u-text u-text-1">
                    <Link to="/about">Excellence is Our Standard.<br/>Mission Success, Our Metric.</Link>
                    </h2>
                </div>
            </div>
            <div className="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1">
                <div className="u-gutter-0 u-layout">
                    <div className="u-layout-row flag-layout-row">
                        <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-size-20-md u-white u-layout-cell-1 flag-item">
                            <div className="u-container-layout">
                              <div className="flag-container">
                                <Link to="/about">
                                  <img className="u-image-1 flag-image" alt="flag_left" src="/images/flag_01.jpg" />
                                </Link>
                              </div>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-layout-cell u-size-20 u-size-20-md u-white u-layout-cell-2 flag-item">
                            <div className="u-container-layout">
                              <div className="flag-container">
                                <Link to="/about">
                                  <img className="u-image-2 flag-image" alt="flag_center" src="/images/flag_02.jpg" />
                                </Link>
                              </div>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-size-20-md u-white u-layout-cell-3 flag-item">
                            <div className="u-container-layout">
                              <div className="flag-container">
                                <Link to="/about">
                                  <img className="u-image-3 flag-image" alt="flag_right" src="/images/flag_03.jpg" />
                                </Link>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-layout-row flag-layout-row">
                        <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-20 u-size-20-md u-white u-layout-cell-1">
                            <div className="u-container-layout">
                              <Link to="/about">
                                <h4 className="u-text u-text-3">Vision</h4>
                              </Link>
                              <p className="u-text u-text-4">Establish CCG as a vanguard in the information warfare environment and as a trusted and invaluable mission partner to our customers.</p>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-layout-cell u-size-20 u-size-20-md u-white u-layout-cell-2">
                            <div className="u-container-layout">
                              <Link to="/about">
                                <h4 className="u-text u-text-5">Mission</h4>
                              </Link>
                              <p className="u-text u-text-6">Provide cutting-edge and innovative solutions to our partners across the full spectrum of technical and physical Cyberspace Operations and Information Operations.</p>
                            </div>
                        </div>
                        <div className="u-align-left u-container-style u-layout-cell u-right-cell u-size-20 u-size-20-md u-white u-layout-cell-3">
                            <div className="u-container-layout">
                              <Link to="/about">
                                <h4 className="u-text u-text-7">Values</h4>
                              </Link>
                              <p className="u-text u-text-8">The foundation on which we build CCG - honesty, integrity, respect and humility. Demand excellence of yourself and team members.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div className="gradient-horizontal-rule" />
        </div>
    </section>
    <section className="u-clearfix u-section-6" id="sec-3f33"><div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-layout">
                <div className="u-layout-row">
                    <div className="u-container-style u-expand-resize u-image u-layout-cell u-left-cell u-size-30 u-image-1">
                        <div className="u-container-layout">
                        </div>
                    </div>
                    <div className="u-align-left-sm u-align-left-xl u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
                        <div className="u-container-layout u-valign-middle u-container-layout-2">
                            <h2 className="u-text u-text-1">Careers at CCG</h2>
                            <p className="u-text u-text-2">Leverage your academic and professional experience thus far and tackle the most challenging problems facing the DoD and IC.</p>
                            <p className="u-text u-text-3">Chameleons are experts at becoming experts, passionate about the Mission, and cannot fathom failure. If you’re interested in defining the future of Cyber Operations, then come join the Team and Stand Out by Blending In.</p>
                            <p className="u-text u-text-3">Click here to learn more about life at CCG and our current opportunities.</p>
                            <Link to="/careers" className="u-btn u-button-style u-blue u-btn-1">
                              See All Opportunities
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Home;
