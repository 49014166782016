import './App.scss';
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';

import AppHeader from './AppHeader';
import About from './About';
import Home from './Home';
import Capabilities from './Capabilities';
import Careers from './Careers';
import Downloads from './Downloads';
import Internships from './Internships';
import Whitepapers from './Whitepapers';
import { theme } from './ThemeOverrides';

function App(props) {

  return (
    <ThemeProvider theme={theme}>

      <div className="App-container">

        <AppHeader />

        <div className="App">
          <Switch>
            <Route path="/capabilities">
              <Capabilities {... props} />
            </Route>
            <Route path="/careers">
              <Careers {... props} />
            </Route>
            <Route path="/internships">
              <Internships />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route exact path="/project_briefs">
              <Whitepapers />
            </Route>
            <Route exact path="/downloads">
              <Downloads />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>

        <div className="ccg-footer-container">
          <footer className="u-clearfix u-footer u-palette-1-base ccg-footer" id="sec-554b">
            <div className="u-clearfix u-sheet u-sheet-1">
              <div className="u-align-center u-container-style u-group u-group-1">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                  <p className="u-small-text u-text u-text-variant u-text-1">
                  &copy; Chameleon Consulting Group, LLC 2023
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(App)
